import { createApp } from "vue";
import App from "./App.vue";
//axios里可以获取store，在store前引入
import Axios from './utils/axios'
import router from "./router";
import store from "./store";
import ElementPlus, { ElTable } from "element-plus";
import "element-plus/dist/index.css";
// import './utils/monitor'

import "./style/global.css";
import "./utils/common.js";
// import Error from './utils/error'
// import lazy from './directives/lazy'
// import mitt from 'mitt' // 导入mitt
const app = createApp(App);
// app.config.globalProperties.$mitt = new mitt() // mitt在vue3中挂载到全局
app
  .use(ElementPlus)
  .use(router)
  .use(store)
	// .use(lazy)
  // .use(Error)
  .mount("#app");
