<script setup>
import autofit from "autofit.js";
import $ from "jquery";
import watermark from "./utils/watermark";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { onBeforeMount, computed, ref, onMounted, watch, onBeforeUnmount } from "vue";
const store = useStore();
const route = useRoute();
const router = useRouter();
const pageInit = computed(() => {
  return store.getters.pageInit;
});
const loading = ref(true);
const userActive = ref(true);
const inactivityTimeout = ref(10 * 60 * 1000); //10分钟未检测到就退出登录，后台正常校验20h
const inactivityTimer = ref(null);
watch(
  () => pageInit.value,
  page => {
    if (page == true) {
      loading.value = false;
    }
  }
);
const startInactivityTimer = () => {
  if (route.name !== "login") {
    inactivityTimer.value = setTimeout(() => {
      store.dispatch("LogOut").then(() => {
        // router.push("/login");
        window.open("/login", "_self");
      });
    }, inactivityTimeout.value);
  }
};
const resetTimer = () => {
  clearTimeout(inactivityTimer.value);
  startInactivityTimer();
};
onMounted(() => {
  // 修复当代码更新时候页面一直处于加载中状态
  if (pageInit.value == true) {
    loading.value = false;
  }
  autofit.init({
    designHeight: 970,
    // designHeight: 929,
    // designHeight: 798,
    designWidth: 1920,
    // designWidth: 1260,
    renderDom: "#app",
    resize: true
  });

  startInactivityTimer();
  window.addEventListener("mousemove", resetTimer);
  window.addEventListener("keydown", resetTimer);
});
onBeforeUnmount(() => {
  window.removeEventListener("mousemove", resetTimer);
  window.removeEventListener("keydown", resetTimer);
});
//全局使用水印
watermark.set("YICONMED");
//反爬
window.addEventListener("load", function () {
  const webdriver = window.navigator.webdriver;
  if (webdriver) {
    window.open("/login", "_self");
    window.localStorage.clear();
  } else {
  }
});
$("#app").click(function (event) {
  if (event && event.originalEvent !== undefined) {
  } else {
    window.open("/login", "_self");
    window.localStorage.clear();
  }
});
</script>

<template>
  <el-skeleton style="width: 80%; margin: 20px auto" :loading="loading" animated @click="resetTimer">
    <template #template>
      <el-skeleton-item variant="h3" style="width: 100%; height: 87px" />
      <el-skeleton-item variant="text" style="width: 100%; margin-top: 16px; margin-right: 16px; height: 200px" />
      <el-skeleton-item variant="button" style="width: 100%; margin-top: 16px" />
      <el-skeleton-item variant="text" style="width: 100%; margin-top: 46px" />
      <el-skeleton-item variant="image" style="width: 100%; height: 240px; margin-top: 46px" />
      <el-skeleton-item variant="text" style="width: 100%; height: 100px; margin-top: 16px" />
    </template>
    <template #default>
      <router-view></router-view>
    </template>
  </el-skeleton>
</template>

<style>
#app {
  width: 100%;
  height: 100vh;
  /* overflow: auto;
  background-color: #f5f5f5; */
  /* width:1260px;
  height: 100vh; */
  /* display: flex; */
  /* box-sizing: border-box; */
}
</style>
